<template>
	<v-container class="mt-5">
		<v-row class="pb-12">
      <v-col cols="12" align-self="center">
			  <v-row align="center" justify="space-around">

			  	<!-- Card uno de la imagen -->
			  	<v-col cols="12" md="6" lg="4">
			  		<v-card class="shadowCard mb-4">
	 						<!-- <lingallery :iid.sync="currentId" :height="50" :items="fotos2" class="white"/> -->
			  		  <v-img :src="url + fotoPrincipal" max-height="500" contain loading="lazy" class="white"  >
			  		  	<v-card-actions class="text-right" v-if="articulo.pjedesc > 0">
	                <v-spacer></v-spacer>
	                <img :src="url + 'promo.png'" height="120"/>
	              </v-card-actions>
			  		  </v-img>
			  		</v-card>

			  		<!-- Minuaturas -->
	  		  	<v-row class="pl-3">
	  		    	<v-col cols="4" md="4" lg="3" class="pl-0" v-for="(foto, i) in fotos" :key="i">
	  		    		<v-card class="shadowCard" height="100" @click="fotoPrincipal = foto.image_name">
	  		    			<v-img :src="url + foto.image_name"  height="100" contain aspect-ratio="2" loading="lazy" class="white"></v-img>
	  		    		</v-card>
	  		    	</v-col>
	  		  	</v-row>
			  	</v-col>

			  	<!-- columna de información -->
			  	<v-col cols="12" md="6" lg="7" xl="4">
			  		<v-card class="elevation-0 transparent">
			        <v-card  class="text-h5 font-weight-bold textocolor--text elevation-0 transparent"> 
			        	{{ articulo.nomart }}
			        </v-card>
						  
						  <!-- SKU -->
						  <div class="grey--text mt-2">SKU: {{ articulo.codigo }}</div>

						  <!-- Precio del artículo con descuento -->
						  <div class="text-decoration-line-through " >
						  	<h3 v-if="articulo.pjedesc > 0">
						  		<span style="font-size: 15px; color:red;" class="pl-2">$ {{ preciodesc }}</span>
						  		<span style="font-size: 10px; color:grey;" class="pl-2">MXN</span>
						  	</h3>
						  </div>

						  <!-- Precio normal del artículo -->
						  <div 
						  	class="text-h5 primary--text font-weight-black mt-6"
						  >
						  	${{ precioFormat }}
						  	<span style="font-size: 15px;" class="primary--text">MXN</span>
						  </div>

						  <div>
						  	<span v-for="(data, i) in famcatsub" :key="i">
						  		<span v-if="data.familia">     {{ data.familia }} </span>
						  		<span v-if="data.categoria"> , {{ data.categoria }} </span>
						  		<span v-if="data.subcategoria">,{{ data.subcategoria }} </span>
						  	</span>
						  </div>

						   <!-- Descripción -->
						  <div class=" text-subtitle-1 mt-6">{{ articulo.descrip }}</div>

						  <!-- <div class="grey--text text-subtitle-1" v-html="articulo.descripLarga"></div> -->
						  <div 
						  	style="white-space: pre-line" 
						  	v-html="articulo.descripLarga"
						  ></div>

						  <v-card-actions class="pl-0 pt-10">
						    <v-btn 
						    	style="border-radius: 0px;" 
						    	large
						    	color="primary"
						    	class="text-caption"
						    >
						    	<span style="font-size: 12px;" @click="agregar_a_carrito_dos()">Agregar al carrito <v-icon>mdi-cart-outline</v-icon></span> 
						  	</v-btn>
						  </v-card-actions>
			  		</v-card>
			  	</v-col>
			  </v-row>
			</v-col>


		</v-row>
		<v-row justify="center">

			<!-- Titulo de la sección -->
      <v-col cols="12" class="text-center py-0"> 
        <div class="d-flex align-center">
          <v-avatar
            size="44"
            rounded
            class="elevation-0 transparent"
          >
            <v-img src="@/assets/iconos/relacionados.png" width="42" height="42"/>
          </v-avatar>
          <div class="ms-3 mt-4">
            <p class="text-xs mb-0">
              <h2 class="font-weight-bold">Productos relacionados</h2>
            </p>
          </div>
        </div>
      </v-col>

			<v-col cols="12" >
	      <v-sheet 
	      	class="transparent pb-4"
          elevation="0"
          color="#f6f9fc"
        >
	        <v-slide-group
            v-model="model"
            class="py-4"
            active-class="success"
            show-arrows
          >
          	<template v-slot:prev="{ on, attrs }">
				      <v-btn
				        color="primary"
				        v-bind="attrs"
				        v-on="on"
				        fab
				        small
				      >
				      	<v-icon>mdi-chevron-left</v-icon>
				      </v-btn>
				    </template>

				    <template v-slot:next="{ on, attrs }">
				      <v-btn
				        color="primary"
				        v-bind="attrs"
				        v-on="on"
				        fab
				        small
				      >
				      	<v-icon>mdi-chevron-right</v-icon>
				      </v-btn>
				    </template>

	          <v-slide-item v-for="(item, i) in productos" :key="i" >

              <v-card  
                class="shadowCard lateral ma-4" 
                :width="$vuetify.breakpoint.name != 'xs'? '300px': '200px' "
              >

              	<v-btn 
			            color="primary" 
			            absolute
			            top
			            left
			            class="v-btn--descuento text-caption"
			            x-small
			            rounded
			            v-if="item.pjedesc > 0"
			          >
			            {{ item.pjedesc }}% Descuento
			          </v-btn>

			          <!-- Ver el artículo -->
			          <v-btn 
			            icon
			            absolute
			            top
			            right
			            class="v-btn--descuento text-caption"
			            small
			            rounded
			            @click="ver(item)"
			          >
			            <v-icon color="black">mdi-eye</v-icon>
			          </v-btn>

                <!-- IMAGEN PRINCIPAL -->
                <v-img 
                  :src="url + item.foto" 
                  height="250px" 
                  contain 
                  v-if="item.foto"
                  @click="verArticulo(item)"
                  class="white"
                >
                  <v-card-actions class="text-right" v-if="item.pjedesc > 0">
                    <v-spacer></v-spacer>
                    <img :src="url + 'promo.png'" height="120"/>
                  </v-card-actions>
                </v-img>

                <!-- SI NO HAY IMAGEN INCERTO DEFAULT-->
                <v-img 
                  :src="`${url + photo}`" 
                  height="250px"
                  contain 
                  v-else 
                  @click="verArticulo(item)" 
                  color="secondary" 
                />

                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <span class="text-subtitle-1 font-weight-bold">{{item.nomart}}</span>
                    </v-col>
                    <v-col cols="12" class="pt-0">
                      <span v-if="item.pjedesc === 0"><h4 class="primary--text mr-2 mb-0"> {{ item.precio1 | currency(2) }} MX </h4></span>
                      <span v-else> {{ (item.precio1) - (item.pjedesc / 100) * (item.precio1 ) | currency(2) }} MX </span>
                    </v-col>
                  </v-row>

                  <v-btn 
                    color="primary" 
                    outlined
                    absolute
                    button
                    right
                    @click="agregar_a_carrito(item)"
                    class="v-btn--example"
                  >
                    <v-icon>mdi-cart</v-icon>
                  </v-btn>
                </v-card-text>

                <v-card-actions class="subtitle-1 font-weight-bold secondary--text py-0" >
                  <v-tooltip bottom v-if="getusuarioFetish">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn fab text small color="white" v-bind="attrs" v-on="on" @click="aniadir_lista_deseos(item)">
                        <v-icon color="red">mdi-heart</v-icon> 
                      </v-btn>
                    </template>
                    <span>Añadir a lista de deseos</span>
                  </v-tooltip>

                  <v-spacer></v-spacer>
                  <v-btn dark text color="error" small v-if="item.pjedesc > 0">  
                    <span class="text-decoration-line-through mx-2"> ${{ item.precio1 }} MX </span>   
                    <span class="font-weight-bold">  -{{ item.pjedesc }} %</span>
                  </v-btn>
                </v-card-actions>
              </v-card>

	          </v-slide-item>
	        </v-slide-group>
	      </v-sheet>
	    </v-col>
		</v-row>
	</v-container>
</template>
<script>
	import lingallery from 'lingallery';
	import Vue from 'vue'

	import $ from 'jquery'
	import {mapGetters, mapActions} from 'vuex'
	import metodos from '@/mixins/metodos.js';
	const accounting = require("accounting");
	Vue.filter('currency', (val, dec) => {
    return accounting.formatMoney(val, '$', dec)
  })
  export default {
  	mixins:[metodos],
    components: {
    	lingallery
    },
    data () {
      return {
			  width: 600,
	      height: 400,
	      fotos2: [],
    		currentId: null,
      	id: 0,
      	articulo:{},
      	fotos: [],
      	url   : this.$http.options.root + 'fotos-fetish/' ,
      	fotoPrincipal: '',
      	activo: true,
      	famcatsub:[],

      	model: null,
        url   : this.$http.options.root + 'fotos-fetish/' ,
        photo : 'nophoto.png',
        productos:[],
        precioFormat: '0.00',
        preciodesc:'0.00',


      }
    },

    created(){
			this.inicialize();
    },

    methods:{
      ...mapActions('Carrito',['carritoadd','CerrarCarrito']),
			async inicialize(){
					if(this.$route.params.id){
					this.id = this.$route.params.id
					await this.init()
					// await this.getfotos()
					await this.getFamCatSub()
					await this.artsRelacionados();
					// await this.irArriba();
				}else{
					this.$router.push({name:'home'})
				}
			},

    	init () {
    		this.$http.get('tienda/articulos.articulo/' + this.id).then(response=>{
    			this.articulo = response.body
    			this.activo = this.articulo.estatus == 1 ? true : false

					if(this.articulo.pjedesc > 0 ){
						this.preciodesc = this.articulo.precio1 * (  (100 - this.articulo.pjedesc) * 0.01 ) 
						this.precioFormat = accounting.formatNumber(this.preciodesc,2)
						this.preciodesc = accounting.formatNumber(this.articulo.precio1,2)
					}else{
						this.precioFormat = accounting.formatNumber(this.articulo.precio1,2)
						this.preciodesc = accounting.formatNumber(this.preciodesc,2)

					}
    			// si activo = true, activar alerta de que artículo no disponible

    			this.$http.get('tienda/articulos/fotos/' + this.articulo.codigo).then(response2=>{
	    			this.fotos = response2.body
	    			const foto = response2.body.find(foto => foto.principal == 1)
	    			if(foto){
	    				this.fotoPrincipal = foto.image_name
	    			}else if(response2.body.length > 0){
	    				this.fotoPrincipal = response2.body[0].image_name
	    			} 
	    			let foto2 = {}
	    			for(const i in this.fotos){
	    				foto2 = {
	    					id: this.fotos[i].idfotos,
	    					src: this.url + this.fotos[i].image_name,
	    					thumbnail: this.url + this.fotos[i].image_name,
	    					alt:'',
	    					caption:''
	    				}
	    				this.fotos2.push(foto2)
	    				console.log(foto2)
	    			}
	    			return
	    		}).catch(error=>{console.log(error)})
    		}).catch(error=>{console.log(error)})

    	},

    	artsRelacionados () {
    		this.$http.get('tienda/articulos.relacionados/' + this.id).then( response =>{
    			let arrayProductos = response.body.map(item=>{
	          return [item.codigo,item]
	        });

	        // Creamos un map de los alumnos
	        var articulos = new Map(arrayProductos); // Pares de clave y valor
	        // y ahora si, extraemos los valores unicos
	        this.productos = [...articulos.values()]; // Conversión a un array

        }).catch(error=>{
					console.log(error)
				}).finally(()=> { 
						this.irArriba();
				 })
    	},
			irArriba(){
					// console.log('VAMONOS PA RIVAA')
    			$('body, html').animate({ scrollTop: '0px' }, 300);
					return true;

			},

    	getfotos () {
    		console.log(this.articulo.codigo)
    		return this.$http.get('tienda/articulos/fotos/' + this.articulo.codigo).then(response=>{
    			this.fotos = response.body
    			const foto = response.body.find(foto => foto.principal == 1)
    			if(foto){
    				this.fotoPrincipal = foto.image_name
    			}else if(response.body.length > 0){
    				this.fotoPrincipal = response.body[0].image_name
    			} 

    		}).catch(error=>{console.log(error)})
    	},

    	getFamCatSub () {
				return this.$http.get('tienda/articulos/fam/' + this.articulo.id).then(response=>{
    			this.famcatsub = response.body
    		}).catch(error=>{console.log(error)})
    	},


    	// 

     //  this.carritoadd(payload).then( response =>{
     //    // this.alerta = { activo: true, texto:`El producto se agrego al carrito`, color:'blue' }
     //    this.ver_carrito();
     //  })
    	agregar_a_carrito_dos () {
				const payload = new Object({
	        id           : this.articulo.id,
	        codigo       : this.articulo.codigo,
	        nomart       : this.articulo.nomart, 
	        precio1      : this.articulo.precio1,
	        precio2      : this.articulo.precio2,
	        pjedesc      : this.articulo.pjedesc,
	        descrip      : this.articulo.descrip,
	        descripLarga : this.articulo.descripLarg,
	        largo        : this.articulo.largo,
	        ancho        : this.articulo.ancho,
	        alto         : this.articulo.alto,
	        peso         : this.articulo.peso,
	        foto         : this.fotoPrincipal,
	        envoltorio   : this.articulo.envoltorio,
	        cantidad     : 1
	      })

        this.carritoadd(payload).then( response =>{
          this.ver_carrito();
        })
    	},

      agregar_a_carrito (item) {
        const payload = new Object({
	        id           : this.articulo.id,
	        codigo       : this.articulo.codigo,
	        nomart       : this.articulo.nomart, 
	        precio1      : this.articulo.precio1,
	        precio2      : this.articulo.precio2,
	        pjedesc      : this.articulo.pjedesc,
	        descrip      : this.articulo.descrip,
	        descripLarga : this.articulo.descripLarg,
	        largo        : this.articulo.largo,
	        ancho        : this.articulo.ancho,
	        alto         : this.articulo.alto,
	        peso         : this.articulo.peso,
	        foto         : this.fotoPrincipal,
	        envoltorio   : this.articulo.envoltorio,
	        cantidad     : 1
	      })

        this.carritoadd(payload).then( response =>{
          this.ver_carrito();
        })
        this.carritoadd(payload).then( response =>{
          this.ver_carrito();
        })
      },

      añadir_lista_deseos (item) {
        const payload = new Object({
          id_cliente: this.getusuarioFetish.idcliente,
          id_producto: item.id,

        })

        this.aniadir_producto_lista_deseos(payload).then(response =>{
          this.alerta = { activo:true, texto: response, color:'blue'}
        }).catch( error =>{
          this.alerta = { activo:true, texto: error, color:'error'}
        })
      },

      verArticulo (art){
      	this.$router.push({ name: 'detalle.del.producto',params:{id:art.id}})
				this.inicialize();
      	// location.reload()
      },

	

    },

    computed: {
      
      tamanioDisplay () {
        return this.$vuetify.breakpoint.height - 100
      },

      tamanio () {
        return this.$vuetify.breakpoint.name
      },
      
    },
  }
</script>
